.vjs-big-play-centered .vjs-big-play-button {
    margin-top: -0.625em;
    margin-left: -0.625em;
    font-size: 5em;
    width: 1.25em;
    height: 1.25em;
    line-height: 1.25em;
    border: none;
    border-radius: 2em;
}

.video-js .vjs-load-progress div {
    background-color: #4B4B4B;
}

.video-js .vjs-slider .vjs-play-progress {
    background-color: #ECBB33;
}

.video-js .vjs-play-progress:before {
    color: #ECBB33;
}

.video-js .vjs-volume-level {
    color: #ECBB33;
    background-color: #ECBB33;
}

.video-js .vjs-volume-bar.vjs-slider-bar.vjs-slider.vjs-slider-horizontal {
    background-color: #4B4B4B;
}

:focus {
    outline-color: #4B4B4B;
}
