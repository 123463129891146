@font-face {
    font-family: "hind-regular";
    src: url('hind-regular.ttf');
}

@font-face {
    font-family: "hind-bold";
    src: url('hind-bold.ttf');
}

@font-face {
    font-family: "ringside-regular-ultra";
    src: url('ringside-regular-ultra.ttf');
}

@font-face {
    font-family: "exo2-regular";
    src: url('exo2-regular.ttf');
}

@font-face {
    font-family: "exo2-semibold";
    src: url('exo2-semibold.ttf');
}

@font-face {
    font-family: "exo2-extrabold";
    src: url('exo2-extrabold.ttf');
}
